import { ObjectEntriesTyped } from "./object-entries.helper";

export const MAX_FORM_LENGTH = 500;
export const MAX_PASSWORD_LENGTH = 80;
export const MAX_INT = 2147483647;

const getFormTouchedEntries = <T extends Record<string, any>>(form: {
    controls: {
        [K in keyof T]: {
            touched: boolean;
            value: T[K];
        };
    };
}) => {
    const touchedEntries: Partial<T> = {};
    ObjectEntriesTyped(form?.controls)
        .filter(([_, val]) => val?.touched)
        .forEach(([key, val]) => {
            touchedEntries[key] = val.value;
        });
    return touchedEntries;
};

const getFormDirtyEntries = <T extends Record<string, any>>(form: {
    controls: {
        [K in keyof T]: {
            dirty: boolean;
            value: T[K];
        };
    };
}) => {
    const touchedEntries: Partial<T> = {};
    ObjectEntriesTyped(form?.controls)
        .filter(([_, val]) => val?.dirty)
        .forEach(([key, val]) => {
            touchedEntries[key] = val.value;
        });
    return touchedEntries;
};

const arraysDifference = (arrayOne: string[], arrayTwo: string[]) => {
    let onlyInArrayOne = [];
    let onlyInArrayTwo = [];
    const share = [];
    const [arrayOneCopy, arrayTwoCopy] = [[...arrayOne], [...arrayTwo]];

    arrayOneCopy.sort();
    arrayTwoCopy.sort();

    while (arrayOneCopy.length !== 0 && arrayTwoCopy.length !== 0) {
        if (arrayOneCopy[0] === arrayTwoCopy[0]) {
            share.push(arrayOneCopy[0]);
            arrayOneCopy.splice(0, 1);
            arrayTwoCopy.splice(0, 1);
        }
        if (arrayOneCopy[0] < arrayTwoCopy[0]) {
            onlyInArrayOne.push(arrayOneCopy[0]);
            arrayOneCopy.splice(0, 1);
        }
        if (arrayOneCopy[0] > arrayTwoCopy[0]) {
            onlyInArrayTwo.push(arrayTwoCopy[0]);
            arrayTwoCopy.splice(0, 1);
        }
    }
    onlyInArrayTwo = onlyInArrayTwo.concat(arrayTwoCopy);
    onlyInArrayOne = onlyInArrayOne.concat(arrayOneCopy);

    return {
        onlyInArrayOne,
        onlyInArrayTwo,
        share,
        diff: onlyInArrayOne.concat(onlyInArrayTwo)
    };
};

const removeUndefinedAttributes = <T extends Record<string, any>>(
    source: T
): Partial<T> | undefined => {
    if (!source) {
        return undefined;
    }
    const obj = { ...source };
    Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
    return obj;
};

export function hasOwnProperty<X>(obj: X, prop: keyof X) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}

const parseMaxInt = (num: string | number) => {
    const i = Number(num);
    if (i >= MAX_INT) {
        return MAX_INT - 1;
    }
    return i;
};

export const formHelper = {
    getFormTouchedEntries,
    getFormDirtyEntries,
    arraysDifference,
    removeUndefinedAttributes,
    parseMaxInt
};
